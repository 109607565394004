<template>
  <div class="h-100">
    <!-- <pre>{{ fields }}</pre> -->
    <div v-if="isLoading" class="px-3 mt-4 h5">Yuklanmoqda...</div>
    <table v-else>
      <thead>
        <tr class="table100-head">
          <th class="column1">Soliq turi</th>
          <th class="column2">Soliq stavkasi</th>
          <th class="column3">Sotilgan NDS</th>
          <th class="column3">Olingan NDS</th>
          <th class="column3">Summa</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>NDS</td>
          <td>{{ (fields.nds_info && fields.nds_info.tax_rate) || 0 }}%</td>
          <td>
            {{ fields.nds_info && toRoundFloat(fields.nds_info.sold_nds) }}
          </td>
          <td>
            {{ fields.nds_info && toRoundFloat(fields.nds_info.bought_nds) }}
          </td>
          <td>
            {{ fields.nds_info && toRoundFloat(fields.nds_info.total) }}
          </td>
        </tr>
        <tr>
          <td>Ishchilik daromad solig'i</td>
          <td>
            {{
              (fields.income_tax_info && fields.income_tax_info.tax_rate) || 0
            }}%
          </td>
          <td></td>
          <td></td>
          <td>
            {{
              (fields.income_tax_info &&
                toRoundFloat(fields.income_tax_info.total)) ||
              0
            }}
          </td>
        </tr>

        <tr>
          <td>Ishchilik sotsial to'lov</td>
          <td>
            {{
              (fields.social_tax_info && fields.social_tax_info.tax_rate) || 0
            }}%
          </td>
          <td></td>
          <td></td>
          <td>
            {{
              (fields.social_tax_info &&
                toRoundFloat(fields.social_tax_info.total)) ||
              0
            }}
          </td>
        </tr>

        <tr>
          <td>Naqd qilish</td>
          <td>
            {{
              (fields.cash_expenditure_info &&
                fields.cash_expenditure_info.tax_rate) ||
              0
            }}%
          </td>
          <td></td>
          <td></td>
          <td>
            {{
              (fields.cash_expenditure_info &&
                toRoundFloat(fields.cash_expenditure_info.total)) ||
              0
            }}
          </td>
        </tr>

        <tr>
          <td>Bank xarajati</td>
          <td>
            {{
              (fields.bank_expenditure_info &&
                fields.bank_expenditure_info.tax_rate) ||
              0
            }}%
          </td>
          <td></td>
          <td></td>
          <td>
            {{
              fields.bank_expenditure_info &&
              toRoundFloat(fields.bank_expenditure_info.total)
            }}
          </td>
        </tr>
        <tr>
          <td class="font-weight-bold">Itogo</td>
          <td></td>
          <td></td>
          <td></td>
          <td class="font-weight-bold">{{ toRoundFloat(cItogo) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import { toRoundFloat } from '@/utils'

export default {
  // filters: { toRoundFloat },
  computed: {
    ...mapState(['reportsStore']),
    ...mapGetters(['filteredReport28']),
    fields() {
      return this.filteredReport28.result || {}
    },

    cItogo() {
      return (
        this.fields &&
        Object.values(JSON.parse(JSON.stringify(this.fields))).reduce(
          (p, c) => !isNaN(c.total) && p + Number(c.total),
          0
        )
      )
    },

    isLoading() {
      return this.$store.state.requests.isLoading
    }
  },

  methods: {
    toRoundFloat,

    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = 'Itogo '
          return
        }
        const values = data.map((item) => Number(item[column.property]))
        if (!values.every((value) => isNaN(value))) {
          sums[index] = toRoundFloat(
            values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
          )
        } else {
          sums[index] = ''
        }
      })

      return sums
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Soliq va foiz xarajatlari' }])
    })
  },

  beforeDestroy() {
    this.$store.dispatch(SET_BREADCRUMB, [])
  }
}
</script>

<style scoped>
table {
  border-spacing: 1;
  background: white;
  border-radius: 4px;
  overflow: hidden;
  width: 100%;
  margin: 0 auto;
  position: relative;
  font-size: 14px;
}

table tr td,
table tr th {
  padding: 12px 14px;
  border-right: 1px solid #ebeef5;
  border-bottom: 1px solid #ebeef5;
  text-align: center;
}
table tr th {
  background: #f5f7fa;
  color: #909399;
}
</style>
